var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __markAsModule = (target) => __defProp(target, "__esModule", { value: true });
var __esm = (fn, res) => function __init() {
  return fn && (res = (0, fn[__getOwnPropNames(fn)[0]])(fn = 0)), res;
};
var __commonJS = (cb, mod) => function __require() {
  return mod || (0, cb[__getOwnPropNames(cb)[0]])((mod = { exports: {} }).exports, mod), mod.exports;
};
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __reExport = (target, module2, copyDefault, desc) => {
  if (module2 && typeof module2 === "object" || typeof module2 === "function") {
    for (let key of __getOwnPropNames(module2))
      if (!__hasOwnProp.call(target, key) && (copyDefault || key !== "default"))
        __defProp(target, key, { get: () => module2[key], enumerable: !(desc = __getOwnPropDesc(module2, key)) || desc.enumerable });
  }
  return target;
};
var __toESM = (module2, isNodeMode) => {
  return __reExport(__markAsModule(__defProp(module2 != null ? __create(__getProtoOf(module2)) : {}, "default", !isNodeMode && module2 && module2.__esModule ? { get: () => module2.default, enumerable: true } : { value: module2, enumerable: true })), module2);
};
var __toCommonJS = /* @__PURE__ */ ((cache) => {
  return (module2, temp) => {
    return cache && cache.get(module2) || (temp = __reExport(__markAsModule({}), module2, 1), cache && cache.set(module2, temp), temp);
  };
})(typeof WeakMap !== "undefined" ? /* @__PURE__ */ new WeakMap() : 0);

// ../../node_modules/tsup/assets/cjs_shims.js
var init_cjs_shims = __esm({
  "../../node_modules/tsup/assets/cjs_shims.js"() {
  }
});

// ../parser/dist/index.js
var require_dist = __commonJS({
  "../parser/dist/index.js"(exports, module2) {
    init_cjs_shims();
    var __defProp2 = Object.defineProperty;
    var __defProps = Object.defineProperties;
    var __getOwnPropDesc2 = Object.getOwnPropertyDescriptor;
    var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
    var __getOwnPropNames2 = Object.getOwnPropertyNames;
    var __getOwnPropSymbols = Object.getOwnPropertySymbols;
    var __hasOwnProp2 = Object.prototype.hasOwnProperty;
    var __propIsEnum = Object.prototype.propertyIsEnumerable;
    var __defNormalProp = (obj, key, value) => key in obj ? __defProp2(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
    var __spreadValues = (a, b) => {
      for (var prop in b || (b = {}))
        if (__hasOwnProp2.call(b, prop))
          __defNormalProp(a, prop, b[prop]);
      if (__getOwnPropSymbols)
        for (var prop of __getOwnPropSymbols(b)) {
          if (__propIsEnum.call(b, prop))
            __defNormalProp(a, prop, b[prop]);
        }
      return a;
    };
    var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
    var __markAsModule2 = (target) => __defProp2(target, "__esModule", { value: true });
    var __export2 = (target, all) => {
      for (var name in all)
        __defProp2(target, name, { get: all[name], enumerable: true });
    };
    var __reExport2 = (target, module22, copyDefault, desc) => {
      if (module22 && typeof module22 === "object" || typeof module22 === "function") {
        for (let key of __getOwnPropNames2(module22))
          if (!__hasOwnProp2.call(target, key) && (copyDefault || key !== "default"))
            __defProp2(target, key, { get: () => module22[key], enumerable: !(desc = __getOwnPropDesc2(module22, key)) || desc.enumerable });
      }
      return target;
    };
    var __toCommonJS2 = /* @__PURE__ */ ((cache) => {
      return (module22, temp) => {
        return cache && cache.get(module22) || (temp = __reExport2(__markAsModule2({}), module22, 1), cache && cache.set(module22, temp), temp);
      };
    })(typeof WeakMap !== "undefined" ? /* @__PURE__ */ new WeakMap() : 0);
    var src_exports2 = {};
    __export2(src_exports2, {
      NodeType: () => NodeType4,
      default: () => src_default2
    });
    var Parser = class {
      constructor(tokenizer, program, tokenExecutor) {
        this._tokenizer = tokenizer;
        this._program = program;
        this._tokenExecutor = tokenExecutor;
        this._stringToTokenize = "";
      }
      parse(stringToTokenize) {
        this._stringToTokenize = stringToTokenize;
        this._tokenizer.initTokenizer(this._stringToTokenize);
        this._tokenExecutor.setLookahead(this._tokenizer.getNextToken());
        return this._program.getProgram();
      }
    };
    var NodeType4 = {
      AdditiveExpression: "AdditiveExpression",
      MultiplicativeExpression: "MultiplicativeExpression",
      PrimaryExpression: "PrimaryExpression",
      ParanthesizedExpression: "ParanthesizedExpression",
      IdentifierExpression: "IdentifierExpression",
      AssignmentExpression: "AssignmentExpression",
      BinaryExpression: "BinaryExpression",
      LogicalExpression: "LogicalExpression",
      LogicalANDExpression: "LogicalANDExpression",
      LogicalORExpression: "LogicalORExpression",
      RelationalExpression: "RelationalExpression",
      EqualityExpression: "EqualityExpression",
      BlockStatement: "BlockStatement",
      EmptyStatement: "EmptyStatement",
      ExpressionStatement: "ExpressionStatement",
      InitStatement: "InitStatement",
      PrintStatement: "PrintStatement",
      IfStatement: "IfStatement",
      WhileStatement: "WhileStatement",
      BreakStatement: "BreakStatement",
      ContinueStatement: "ContinueStatement",
      VariableStatement: "VariableStatement",
      BooleanLiteral: "BooleanLiteral",
      NumericLiteral: "NumericLiteral",
      StringLiteral: "StringLiteral",
      NullLiteral: "NullLiteral",
      VariableDeclaration: "VariableDeclaration",
      Program: "Program"
    };
    var Program2 = class {
      constructor(statementList) {
        this._statementList = statementList;
      }
      getProgram() {
        return {
          type: NodeType4.Program,
          body: this._statementList.getInitialStatementList()
        };
      }
    };
    var TokenTypes = {
      NULL_TYPE: null,
      HI_BHAI_TYPE: "hi bhai",
      BYE_BHAI_TYPE: "bye bhai",
      BOL_BHAI_TYPE: "bol bhai",
      BHAI_YE_HAI_TYPE: "bhai ye hai",
      AGAR_BHAI: "agar bhai",
      WARNA_BHAI: "warna bhai",
      NAHI_TO_BHAI: "nahi to bhai",
      JAB_TAK_BHAI: "jab tak bhai",
      BAS_KAR_BHAI: "bas kar bhai",
      AGLA_DEKH_BHAI: "agla dekh bhai",
      NALLA_TYPE: "NALLA",
      SEMI_COLON_TYPE: ";",
      OPEN_CURLY_BRACE_TYPE: "{",
      CLOSED_CURLY_BRACE_TYPE: "}",
      OPEN_PARENTHESIS_TYPE: "(",
      CLOSED_PARENTHESIS_TYPE: ")",
      COMMA_TYPE: ",",
      NUMBER_TYPE: "NUMBER",
      IDENTIFIER_TYPE: "IDENTIFIER",
      SIMPLE_ASSIGN_TYPE: "SIMPLE_ASSIGN",
      COMPLEX_ASSIGN_TYPE: "COMPLEX_ASSIGN",
      ADDITIVE_OPERATOR_TYPE: "ADDITIVE_OPERATOR",
      MULTIPLICATIVE_OPERATOR_TYPE: "MULTIPLICATIVE_OPERATOR",
      RELATIONAL_OPERATOR: "RELATIONAL_OPERATOR",
      EQUALITY_OPERATOR: "EQUALITY_OPERATOR",
      STRING_TYPE: "STRING",
      BOOLEAN_TYPE: "BOOLEAN",
      LOGICAL_AND: "LOGICAL_AND",
      LOGICAL_OR: "LOGICAL_OR"
    };
    var SPEC = [
      { regex: /^\s+/, tokenType: TokenTypes.NULL_TYPE },
      { regex: /^\/\/.*/, tokenType: TokenTypes.NULL_TYPE },
      { regex: /^\/\*[\s\S]*?\*\//, tokenType: TokenTypes.NULL_TYPE },
      { regex: /^;/, tokenType: TokenTypes.SEMI_COLON_TYPE },
      { regex: /^\{/, tokenType: TokenTypes.OPEN_CURLY_BRACE_TYPE },
      { regex: /^\}/, tokenType: TokenTypes.CLOSED_CURLY_BRACE_TYPE },
      { regex: /^\(/, tokenType: TokenTypes.OPEN_PARENTHESIS_TYPE },
      { regex: /^\)/, tokenType: TokenTypes.CLOSED_PARENTHESIS_TYPE },
      { regex: /^,/, tokenType: TokenTypes.COMMA_TYPE },
      { regex: /^\bhi bhai\b/, tokenType: TokenTypes.HI_BHAI_TYPE },
      { regex: /^\bbye bhai\b/, tokenType: TokenTypes.BYE_BHAI_TYPE },
      { regex: /^\bbol bhai\b/, tokenType: TokenTypes.BOL_BHAI_TYPE },
      { regex: /^\bbhai ye hai\b/, tokenType: TokenTypes.BHAI_YE_HAI_TYPE },
      { regex: /^\bagar bhai\b/, tokenType: TokenTypes.AGAR_BHAI },
      { regex: /^\bnahi to bhai\b/, tokenType: TokenTypes.NAHI_TO_BHAI },
      { regex: /^\bwarna bhai\b/, tokenType: TokenTypes.WARNA_BHAI },
      { regex: /^\bnalla\b/, tokenType: TokenTypes.NALLA_TYPE },
      { regex: /^\bjab tak bhai\b/, tokenType: TokenTypes.JAB_TAK_BHAI },
      { regex: /^\bbas kar bhai\b/, tokenType: TokenTypes.BAS_KAR_BHAI },
      { regex: /^\bagla dekh bhai\b/, tokenType: TokenTypes.AGLA_DEKH_BHAI },
      { regex: /^[+-]?([\d]*[.])?[\d]+/, tokenType: TokenTypes.NUMBER_TYPE },
      { regex: /^\bsahi\b/, tokenType: TokenTypes.BOOLEAN_TYPE },
      { regex: /^\bgalat\b/, tokenType: TokenTypes.BOOLEAN_TYPE },
      { regex: /^\w+/, tokenType: TokenTypes.IDENTIFIER_TYPE },
      { regex: /^[=!]=/, tokenType: TokenTypes.EQUALITY_OPERATOR },
      { regex: /^=/, tokenType: TokenTypes.SIMPLE_ASSIGN_TYPE },
      { regex: /^[\*\%\/\+\-]=/, tokenType: TokenTypes.COMPLEX_ASSIGN_TYPE },
      { regex: /^[+\-]/, tokenType: TokenTypes.ADDITIVE_OPERATOR_TYPE },
      { regex: /^[*\/\%]/, tokenType: TokenTypes.MULTIPLICATIVE_OPERATOR_TYPE },
      { regex: /^[><]=?/, tokenType: TokenTypes.RELATIONAL_OPERATOR },
      { regex: /^&&/, tokenType: TokenTypes.LOGICAL_AND },
      { regex: /^\|\|/, tokenType: TokenTypes.LOGICAL_OR },
      { regex: /^"[^"]*"/, tokenType: TokenTypes.STRING_TYPE },
      { regex: /^'[^']*'/, tokenType: TokenTypes.STRING_TYPE }
    ];
    var Statement = class {
      constructor(tokenExecutor) {
        this._tokenExecutor = tokenExecutor;
      }
      static getStatementImpl(lookahead) {
        switch (lookahead.type) {
          case TokenTypes.BOL_BHAI_TYPE:
            return BhaiLangModule.getPrintStatement();
          case TokenTypes.SEMI_COLON_TYPE:
            return BhaiLangModule.getEmptyStatement();
          case TokenTypes.OPEN_CURLY_BRACE_TYPE:
            return BhaiLangModule.getBlockStatement();
          case TokenTypes.BHAI_YE_HAI_TYPE:
            return BhaiLangModule.getVariableStatement();
          case TokenTypes.AGAR_BHAI:
            return BhaiLangModule.getIfStatement();
          case TokenTypes.JAB_TAK_BHAI:
            return BhaiLangModule.getWhileStatement();
          case TokenTypes.BAS_KAR_BHAI:
            return BhaiLangModule.getBreakStatement();
          case TokenTypes.AGLA_DEKH_BHAI:
            return BhaiLangModule.getContinueStatement();
          default:
            return BhaiLangModule.getExpressionStatement();
        }
      }
    };
    var BlockStatement2 = class extends Statement {
      constructor(tokenExecutor, statementList) {
        super(tokenExecutor);
        this._statementList = statementList;
      }
      getStatement() {
        var _a;
        this._tokenExecutor.eatTokenAndForwardLookahead(TokenTypes.OPEN_CURLY_BRACE_TYPE);
        const body = ((_a = this._tokenExecutor.getLookahead()) == null ? void 0 : _a.type) === TokenTypes.CLOSED_CURLY_BRACE_TYPE ? [] : this._statementList.getStatementList(TokenTypes.CLOSED_CURLY_BRACE_TYPE);
        this._tokenExecutor.eatTokenAndForwardLookahead(TokenTypes.CLOSED_CURLY_BRACE_TYPE);
        this._tokenExecutor.eatOptionalSemiColonToken();
        return {
          type: NodeType4.BlockStatement,
          body
        };
      }
    };
    var BreakStatement2 = class extends Statement {
      getStatement() {
        this._tokenExecutor.eatTokenAndForwardLookahead(TokenTypes.BAS_KAR_BHAI);
        return {
          type: NodeType4.BreakStatement
        };
      }
    };
    var ContinueStatement2 = class extends Statement {
      getStatement() {
        this._tokenExecutor.eatTokenAndForwardLookahead(TokenTypes.AGLA_DEKH_BHAI);
        return {
          type: NodeType4.ContinueStatement
        };
      }
    };
    var EmptyStatement2 = class extends Statement {
      getStatement() {
        this._tokenExecutor.eatTokenAndForwardLookahead(TokenTypes.SEMI_COLON_TYPE);
        return {
          type: NodeType4.EmptyStatement
        };
      }
    };
    var Expression = class {
      constructor(tokenExecutor) {
        this._tokenExecutor = tokenExecutor;
      }
      static getExpressionImpl(expressionType) {
        switch (expressionType) {
          case NodeType4.AdditiveExpression:
            return BhaiLangModule.getAdditiveExpression();
          case NodeType4.MultiplicativeExpression:
            return BhaiLangModule.getMultiplicativeExpression();
          case NodeType4.PrimaryExpression:
            return BhaiLangModule.getPrimaryExpression();
          case NodeType4.ParanthesizedExpression:
            return BhaiLangModule.getParanthesizedExpression();
          case NodeType4.AssignmentExpression:
            return BhaiLangModule.getAssignmentExpression();
          case NodeType4.EqualityExpression:
            return BhaiLangModule.getEqualityExpression();
          case NodeType4.LogicalANDExpression:
            return BhaiLangModule.getLogicalANDExpression();
          case NodeType4.LogicalORExpression:
            return BhaiLangModule.getLogicalORExpression();
          case NodeType4.RelationalExpression:
            return BhaiLangModule.getRelationalExpression();
          default:
            return BhaiLangModule.getIndentifierExpression();
        }
      }
      getBinaryExpression(downstreamExpressionType, operatorToken) {
        return this._getExpression(downstreamExpressionType, operatorToken, NodeType4.BinaryExpression);
      }
      getLogicalExpression(downstreamExpressionType, operatorToken) {
        return this._getExpression(downstreamExpressionType, operatorToken, NodeType4.LogicalExpression);
      }
      _getExpression(downstreamExpressionType, operatorToken, expressionType) {
        var _a;
        let left = Expression.getExpressionImpl(downstreamExpressionType).getExpression();
        while (((_a = this._tokenExecutor.getLookahead()) == null ? void 0 : _a.type) === operatorToken) {
          const operator = this._tokenExecutor.eatTokenAndForwardLookahead(operatorToken);
          const right = Expression.getExpressionImpl(downstreamExpressionType).getExpression();
          left = {
            type: expressionType,
            operator: operator.value,
            left,
            right
          };
        }
        return left;
      }
    };
    var AdditiveExpression = class extends Expression {
      getExpression() {
        return this.getBinaryExpression(NodeType4.MultiplicativeExpression, TokenTypes.ADDITIVE_OPERATOR_TYPE);
      }
    };
    var AssignmentExpression2 = class extends Expression {
      constructor() {
        super(...arguments);
        this._logicalORExpression = Expression.getExpressionImpl(NodeType4.LogicalORExpression);
      }
      getExpression() {
        var _a;
        const left = this._logicalORExpression.getExpression();
        if (!this._isAssignmentOperator((_a = this._tokenExecutor.getLookahead()) == null ? void 0 : _a.type)) {
          return left;
        }
        return {
          type: NodeType4.AssignmentExpression,
          operator: this._getAssignmentOperator().value,
          left: this._checkValidAssignmentTarget(left),
          right: this.getExpression()
        };
      }
      _isAssignmentOperator(tokenType) {
        return tokenType && (tokenType === TokenTypes.SIMPLE_ASSIGN_TYPE || tokenType === TokenTypes.COMPLEX_ASSIGN_TYPE);
      }
      _getAssignmentOperator() {
        const lookahead = this._tokenExecutor.getLookahead();
        if (!lookahead || lookahead.type === TokenTypes.SIMPLE_ASSIGN_TYPE)
          return this._tokenExecutor.eatTokenAndForwardLookahead(TokenTypes.SIMPLE_ASSIGN_TYPE);
        return this._tokenExecutor.eatTokenAndForwardLookahead(TokenTypes.COMPLEX_ASSIGN_TYPE);
      }
      _checkValidAssignmentTarget(node) {
        if (node.type === NodeType4.IdentifierExpression)
          return node;
        throw new SyntaxError("Invalid left hand side in assignment expression");
      }
    };
    var EqualityExpression = class extends Expression {
      getExpression() {
        return this.getBinaryExpression(NodeType4.RelationalExpression, TokenTypes.EQUALITY_OPERATOR);
      }
    };
    var IdentifierExpression2 = class extends Expression {
      getExpression() {
        const name = this._tokenExecutor.eatTokenAndForwardLookahead(TokenTypes.IDENTIFIER_TYPE).value;
        return {
          type: NodeType4.IdentifierExpression,
          name
        };
      }
    };
    var UnsupportedTypeException = class extends Error {
      constructor(errorMessage) {
        super(errorMessage);
        this.name = "UnsupportedTypeException";
        this.message = errorMessage;
      }
    };
    var Literal = class {
      constructor(tokenExecutor) {
        this._tokenExecutor = tokenExecutor;
      }
      static getLiteralImpl(tokenType) {
        switch (tokenType) {
          case TokenTypes.NUMBER_TYPE:
            return BhaiLangModule.getNumericLiteral();
          case TokenTypes.BOOLEAN_TYPE:
            return BhaiLangModule.getBooleanLiteral();
          case TokenTypes.STRING_TYPE:
            return BhaiLangModule.getStringLiteral();
          case TokenTypes.NALLA_TYPE:
            return BhaiLangModule.getNullLiteral();
          default:
            throw new UnsupportedTypeException(`Token type not supproted for literal: ${tokenType}`);
        }
      }
    };
    var BooleanLiteral2 = class extends Literal {
      getLiteral() {
        const token = this._tokenExecutor.eatTokenAndForwardLookahead(TokenTypes.BOOLEAN_TYPE);
        return {
          type: NodeType4.BooleanLiteral,
          value: token.value
        };
      }
    };
    var NullLiteral2 = class extends Literal {
      getLiteral() {
        return {
          type: NodeType4.NullLiteral,
          value: "nalla"
        };
      }
    };
    var NumericLiteral2 = class extends Literal {
      getLiteral() {
        const token = this._tokenExecutor.eatTokenAndForwardLookahead(TokenTypes.NUMBER_TYPE);
        return {
          type: NodeType4.NumericLiteral,
          value: Number(token.value)
        };
      }
    };
    var StringLiteral2 = class extends Literal {
      getLiteral() {
        const token = this._tokenExecutor.eatTokenAndForwardLookahead(TokenTypes.STRING_TYPE);
        return {
          type: NodeType4.StringLiteral,
          value: token.value.slice(1, -1)
        };
      }
    };
    var LogicalANDExpression = class extends Expression {
      getExpression() {
        return this.getLogicalExpression(NodeType4.EqualityExpression, TokenTypes.LOGICAL_AND);
      }
    };
    var LogicalORExpression = class extends Expression {
      getExpression() {
        return this.getLogicalExpression(NodeType4.LogicalANDExpression, TokenTypes.LOGICAL_OR);
      }
    };
    var MultiplicativeExpression = class extends Expression {
      getExpression() {
        return this.getBinaryExpression(NodeType4.PrimaryExpression, TokenTypes.MULTIPLICATIVE_OPERATOR_TYPE);
      }
    };
    var ParanthesizedExpression = class extends Expression {
      getExpression() {
        this._tokenExecutor.eatTokenAndForwardLookahead(TokenTypes.OPEN_PARENTHESIS_TYPE);
        const expression = Expression.getExpressionImpl(NodeType4.AssignmentExpression).getExpression();
        this._tokenExecutor.eatTokenAndForwardLookahead(TokenTypes.CLOSED_PARENTHESIS_TYPE);
        return expression;
      }
    };
    var PrimaryExpression = class extends Expression {
      getExpression() {
        const token = this._tokenExecutor.getLookahead();
        switch (token == null ? void 0 : token.type) {
          case TokenTypes.OPEN_PARENTHESIS_TYPE:
            return Expression.getExpressionImpl(NodeType4.ParanthesizedExpression).getExpression();
          case TokenTypes.STRING_TYPE:
          case TokenTypes.NUMBER_TYPE:
          case TokenTypes.BOOLEAN_TYPE:
            return Literal.getLiteralImpl(token.type).getLiteral();
          case TokenTypes.NALLA_TYPE:
            return this._getNallaLiteral();
          default:
            return this._getLeftHandSideExpression();
        }
      }
      _getNallaLiteral() {
        this._tokenExecutor.eatTokenAndForwardLookahead(TokenTypes.NALLA_TYPE);
        return Literal.getLiteralImpl(TokenTypes.NALLA_TYPE).getLiteral();
      }
      _getLeftHandSideExpression() {
        return Expression.getExpressionImpl(NodeType4.IdentifierExpression).getExpression();
      }
    };
    var RelationalExpression = class extends Expression {
      getExpression() {
        return this.getBinaryExpression(NodeType4.AdditiveExpression, TokenTypes.RELATIONAL_OPERATOR);
      }
    };
    var ExpressionStatement2 = class extends Statement {
      getStatement() {
        const expression = Expression.getExpressionImpl(NodeType4.AssignmentExpression).getExpression();
        this._tokenExecutor.eatTokenAndForwardLookahead(TokenTypes.SEMI_COLON_TYPE);
        return {
          type: NodeType4.ExpressionStatement,
          expression
        };
      }
    };
    var HANDLED_LOOP_TOKEN_TYPES = [TokenTypes.WARNA_BHAI, TokenTypes.NAHI_TO_BHAI];
    var IfStatement2 = class extends Statement {
      constructor(tokenExecutor) {
        super(tokenExecutor);
      }
      getConditionalStatement(tokenType) {
        this._tokenExecutor.eatTokenAndForwardLookahead(tokenType);
        this._tokenExecutor.eatTokenAndForwardLookahead(TokenTypes.OPEN_PARENTHESIS_TYPE);
        const test = Expression.getExpressionImpl(NodeType4.AssignmentExpression).getExpression();
        this._tokenExecutor.eatTokenAndForwardLookahead(TokenTypes.CLOSED_PARENTHESIS_TYPE);
        if (this._tokenExecutor.getLookahead() == null) {
          throw new SyntaxError(`Unexpected end of "${tokenType}" statement`);
        }
        const consequent = Statement.getStatementImpl(this._tokenExecutor.getLookahead()).getStatement();
        return {
          type: NodeType4.IfStatement,
          test,
          consequent
        };
      }
      getStatement() {
        const ifStatement = this.getConditionalStatement(TokenTypes.AGAR_BHAI);
        const alternates = [];
        for (let lookahead = this._tokenExecutor.getLookahead(); lookahead !== null && HANDLED_LOOP_TOKEN_TYPES.includes(lookahead.type); lookahead = this._tokenExecutor.getLookahead()) {
          if (lookahead.type === TokenTypes.WARNA_BHAI) {
            alternates.push(this._tokenExecutor.eatTokenAndForwardLookahead(lookahead.type) && Statement.getStatementImpl(this._tokenExecutor.getLookahead()).getStatement());
            break;
          } else if (lookahead.type === TokenTypes.NAHI_TO_BHAI) {
            alternates.push(this.getConditionalStatement(TokenTypes.NAHI_TO_BHAI));
          }
        }
        return __spreadProps(__spreadValues({}, ifStatement), {
          alternates
        });
      }
    };
    var InitStatement2 = class extends Statement {
      constructor(tokenExecutor, statementList) {
        super(tokenExecutor);
        this._statementList = statementList;
      }
      getStatement() {
        var _a;
        this._tokenExecutor.eatTokenAndForwardLookahead(TokenTypes.HI_BHAI_TYPE);
        this._tokenExecutor.eatOptionalSemiColonToken();
        const body = ((_a = this._tokenExecutor.getLookahead()) == null ? void 0 : _a.type) !== TokenTypes.BYE_BHAI_TYPE ? this._statementList.getStatementList(TokenTypes.BYE_BHAI_TYPE) : [];
        this._tokenExecutor.eatTokenAndForwardLookahead(TokenTypes.BYE_BHAI_TYPE);
        this._tokenExecutor.eatOptionalSemiColonToken();
        return {
          type: NodeType4.InitStatement,
          body
        };
      }
    };
    var PrintStatement2 = class extends Statement {
      getStatement() {
        this._tokenExecutor.eatTokenAndForwardLookahead(TokenTypes.BOL_BHAI_TYPE);
        const expressions = this._getExpressionList();
        this._tokenExecutor.eatTokenAndForwardLookahead(TokenTypes.SEMI_COLON_TYPE);
        return {
          type: NodeType4.PrintStatement,
          expressions
        };
      }
      _getExpressionList() {
        var _a;
        const expressions = [];
        do {
          expressions.push(this._getExpression());
        } while (((_a = this._tokenExecutor.getLookahead()) == null ? void 0 : _a.type) === TokenTypes.COMMA_TYPE && this._tokenExecutor.eatTokenAndForwardLookahead(TokenTypes.COMMA_TYPE));
        return expressions;
      }
      _getExpression() {
        return Expression.getExpressionImpl(NodeType4.AssignmentExpression).getExpression();
      }
    };
    var VariableStatement2 = class extends Statement {
      constructor(tokenExecutor, nullLiteral) {
        super(tokenExecutor);
        this._nullLiteral = nullLiteral;
      }
      getStatement() {
        this._tokenExecutor.eatTokenAndForwardLookahead(TokenTypes.BHAI_YE_HAI_TYPE);
        const declarations = this._getVariableDeclarationList();
        this._tokenExecutor.eatTokenAndForwardLookahead(TokenTypes.SEMI_COLON_TYPE);
        return {
          type: NodeType4.VariableStatement,
          declarations
        };
      }
      _getVariableDeclarationList() {
        var _a;
        const declarations = [];
        do {
          declarations.push(this._getVariableDeclaration());
        } while (((_a = this._tokenExecutor.getLookahead()) == null ? void 0 : _a.type) === TokenTypes.COMMA_TYPE && this._tokenExecutor.eatTokenAndForwardLookahead(TokenTypes.COMMA_TYPE));
        return declarations;
      }
      _getVariableDeclaration() {
        var _a, _b;
        const id = Expression.getExpressionImpl(NodeType4.IdentifierExpression).getExpression();
        const init = ((_a = this._tokenExecutor.getLookahead()) == null ? void 0 : _a.type) !== TokenTypes.SEMI_COLON_TYPE && ((_b = this._tokenExecutor.getLookahead()) == null ? void 0 : _b.type) !== TokenTypes.COMMA_TYPE ? this._getVariableInitializer() : this._nullLiteral.getLiteral();
        return {
          type: NodeType4.VariableDeclaration,
          id,
          init
        };
      }
      _getVariableInitializer() {
        this._tokenExecutor.eatTokenAndForwardLookahead(TokenTypes.SIMPLE_ASSIGN_TYPE);
        return Expression.getExpressionImpl(NodeType4.AssignmentExpression).getExpression();
      }
    };
    var WhileStatement2 = class extends Statement {
      getStatement() {
        this._tokenExecutor.eatTokenAndForwardLookahead(TokenTypes.JAB_TAK_BHAI);
        this._tokenExecutor.eatTokenAndForwardLookahead(TokenTypes.OPEN_PARENTHESIS_TYPE);
        const test = Expression.getExpressionImpl(NodeType4.AssignmentExpression).getExpression();
        this._tokenExecutor.eatTokenAndForwardLookahead(TokenTypes.CLOSED_PARENTHESIS_TYPE);
        if (this._tokenExecutor.getLookahead() == null) {
          throw new SyntaxError(`Unexpected end of "jab tak bhai" statement`);
        }
        const body = Statement.getStatementImpl(this._tokenExecutor.getLookahead()).getStatement();
        return {
          type: NodeType4.WhileStatement,
          test,
          body
        };
      }
    };
    var StatementList = class {
      constructor(tokenExecutor) {
        this._tokenExecutor = tokenExecutor;
      }
      getInitialStatementList() {
        for (let lookahead = this._tokenExecutor.getLookahead(); lookahead !== null && lookahead.type !== TokenTypes.HI_BHAI_TYPE; lookahead = this._tokenExecutor.getLookahead()) {
          this._tokenExecutor.eatTokenAndForwardLookahead(lookahead.type);
        }
        return BhaiLangModule.getInitStatement().getStatement();
      }
      getStatementList(stopLookaheadType) {
        const statementlist = [];
        for (let lookahead = this._tokenExecutor.getLookahead(); lookahead !== null && lookahead.type !== stopLookaheadType; lookahead = this._tokenExecutor.getLookahead()) {
          statementlist.push(Statement.getStatementImpl(lookahead).getStatement());
        }
        return statementlist;
      }
    };
    var TokenExecutor = class {
      constructor(tokenizer) {
        this._lookahead = null;
        this._tokenizer = tokenizer;
      }
      eatTokenAndForwardLookahead(tokenType) {
        const token = this._lookahead;
        if (token == null) {
          throw new SyntaxError(`Unexpected end of input, expected : "${tokenType}"`);
        }
        if (token.type !== tokenType) {
          throw new SyntaxError(`kya kar rha hai tu??...Unexpected token: "${token.value}", expected : "${tokenType}"`);
        }
        this.setLookahead(this._tokenizer.getNextToken());
        return token;
      }
      eatOptionalSemiColonToken() {
        var _a;
        if (((_a = this.getLookahead()) == null ? void 0 : _a.type) == TokenTypes.SEMI_COLON_TYPE)
          this.eatTokenAndForwardLookahead(TokenTypes.SEMI_COLON_TYPE);
      }
      getLookahead() {
        return this._lookahead;
      }
      setLookahead(lookahead) {
        this._lookahead = lookahead;
      }
    };
    var InvalidStateException2 = class extends Error {
      constructor(errorMessage) {
        super(errorMessage);
        this.name = "InvalidStateException";
        this.message = errorMessage;
      }
    };
    var TokenizerImpl = class {
      constructor(spec) {
        this._string = void 0;
        this._spec = spec;
        this._cursor = 0;
      }
      initTokenizer(stringToTokenize) {
        this._string = stringToTokenize;
        this._cursor = 0;
      }
      isEOF() {
        if (!this._string)
          return true;
        return this._cursor === this._string.length;
      }
      hasMoreTokens() {
        if (!this._string)
          return false;
        return this._cursor < this._string.length;
      }
      getNextToken() {
        if (!this._string)
          throw new InvalidStateException2("Tokenizer is not initialized with string. Please call initTokenizer method first.");
        if (!this.hasMoreTokens()) {
          return null;
        }
        const string = this._string.slice(this._cursor);
        for (const { regex, tokenType } of this._spec) {
          const tokenValue = this._matched(regex, string);
          if (tokenValue === null) {
            continue;
          }
          if (tokenType === null) {
            return this.getNextToken();
          }
          return {
            type: tokenType,
            value: tokenValue
          };
        }
        throw new SyntaxError(`Kya kar rha hai tu??...Unexpected token: "${string[0]}"`);
      }
      _matched(regex, string) {
        const matched = regex.exec(string);
        if (matched === null) {
          return null;
        }
        this._cursor += matched[0].length;
        return matched[0];
      }
    };
    var BhaiLangModule = class {
      static getTokenizer() {
        if (!this._tokenizer)
          this._tokenizer = new TokenizerImpl(SPEC);
        return this._tokenizer;
      }
      static getTokenExecutor() {
        if (!this._tokenExecutor)
          this._tokenExecutor = new TokenExecutor(this.getTokenizer());
        return this._tokenExecutor;
      }
      static getStatementList() {
        if (!this._statementList)
          this._statementList = new StatementList(this.getTokenExecutor());
        return this._statementList;
      }
      static getInitStatement() {
        if (!this._initStatement)
          this._initStatement = new InitStatement2(this.getTokenExecutor(), this.getStatementList());
        return this._initStatement;
      }
      static getPrintStatement() {
        if (!this._printStatement) {
          this._printStatement = new PrintStatement2(this.getTokenExecutor());
        }
        return this._printStatement;
      }
      static getIfStatement() {
        if (!this._ifStatement) {
          this._ifStatement = new IfStatement2(this.getTokenExecutor());
        }
        return this._ifStatement;
      }
      static getBreakStatement() {
        if (!this._breakStatement) {
          this._breakStatement = new BreakStatement2(this.getTokenExecutor());
        }
        return this._breakStatement;
      }
      static getContinueStatement() {
        if (!this._continueStatement) {
          this._continueStatement = new ContinueStatement2(this.getTokenExecutor());
        }
        return this._continueStatement;
      }
      static getWhileStatement() {
        if (!this._whileStatement) {
          this._whileStatement = new WhileStatement2(this.getTokenExecutor());
        }
        return this._whileStatement;
      }
      static getExpressionStatement() {
        if (!this._expresionStatement) {
          this._expresionStatement = new ExpressionStatement2(this.getTokenExecutor());
        }
        return this._expresionStatement;
      }
      static getEmptyStatement() {
        if (!this._emptyStatement) {
          this._emptyStatement = new EmptyStatement2(this.getTokenExecutor());
        }
        return this._emptyStatement;
      }
      static getBlockStatement() {
        if (!this._blockStatement) {
          this._blockStatement = new BlockStatement2(this.getTokenExecutor(), this.getStatementList());
        }
        return this._blockStatement;
      }
      static getVariableStatement() {
        if (!this._variableStatement)
          this._variableStatement = new VariableStatement2(this.getTokenExecutor(), this.getNullLiteral());
        return this._variableStatement;
      }
      static getAdditiveExpression() {
        if (!this._additiveExpression) {
          this._additiveExpression = new AdditiveExpression(this.getTokenExecutor());
        }
        return this._additiveExpression;
      }
      static getMultiplicativeExpression() {
        if (!this._multiplicativeExpression) {
          this._multiplicativeExpression = new MultiplicativeExpression(this.getTokenExecutor());
        }
        return this._multiplicativeExpression;
      }
      static getPrimaryExpression() {
        if (!this._primaryExpression) {
          this._primaryExpression = new PrimaryExpression(this.getTokenExecutor());
        }
        return this._primaryExpression;
      }
      static getParanthesizedExpression() {
        if (!this._paranthesizedExpression) {
          this._paranthesizedExpression = new ParanthesizedExpression(this.getTokenExecutor());
        }
        return this._paranthesizedExpression;
      }
      static getIndentifierExpression() {
        if (!this._idetifierExpression)
          this._idetifierExpression = new IdentifierExpression2(this.getTokenExecutor());
        return this._idetifierExpression;
      }
      static getEqualityExpression() {
        if (!this._equalityExpression)
          this._equalityExpression = new EqualityExpression(this.getTokenExecutor());
        return this._equalityExpression;
      }
      static getLogicalANDExpression() {
        if (!this._logicalANDExpression)
          this._logicalANDExpression = new LogicalANDExpression(this.getTokenExecutor());
        return this._logicalANDExpression;
      }
      static getLogicalORExpression() {
        if (!this._logicalORExpression)
          this._logicalORExpression = new LogicalORExpression(this.getTokenExecutor());
        return this._logicalORExpression;
      }
      static getRelationalExpression() {
        if (!this._relationalExpression)
          this._relationalExpression = new RelationalExpression(this.getTokenExecutor());
        return this._relationalExpression;
      }
      static getAssignmentExpression() {
        if (!this._assignmentExpression)
          this._assignmentExpression = new AssignmentExpression2(this.getTokenExecutor());
        return this._assignmentExpression;
      }
      static getNumericLiteral() {
        if (!this._numericLiteral) {
          this._numericLiteral = new NumericLiteral2(this.getTokenExecutor());
        }
        return this._numericLiteral;
      }
      static getStringLiteral() {
        if (!this._stringLiteral) {
          this._stringLiteral = new StringLiteral2(this.getTokenExecutor());
        }
        return this._stringLiteral;
      }
      static getBooleanLiteral() {
        if (!this._booleanLiteral) {
          this._booleanLiteral = new BooleanLiteral2(this.getTokenExecutor());
        }
        return this._booleanLiteral;
      }
      static getNullLiteral() {
        if (!this._nullLiteral) {
          this._nullLiteral = new NullLiteral2(this.getTokenExecutor());
        }
        return this._nullLiteral;
      }
      static getProgram() {
        if (!this._program)
          this._program = new Program2(this.getStatementList());
        return this._program;
      }
      static getParser() {
        if (!this._parser)
          this._parser = new Parser(this.getTokenizer(), this.getProgram(), this.getTokenExecutor());
        return this._parser;
      }
    };
    var src_default2 = BhaiLangModule.getParser();
    module2.exports = __toCommonJS2(src_exports2);
  }
});

// src/index.ts
var src_exports = {};
__export(src_exports, {
  RuntimeException: () => RuntimeException,
  default: () => src_default
});
init_cjs_shims();

// src/module/interpreterModule.ts
init_cjs_shims();
var import_bhai_lang_parser3 = __toESM(require_dist());

// src/components/interpreter.ts
init_cjs_shims();

// src/components/scope.ts
init_cjs_shims();

// src/exceptions/runtimeException.ts
init_cjs_shims();
var RuntimeException = class extends Error {
  constructor(errorMessage) {
    const errorName = "RuntimeException";
    errorMessage = errorName + ": " + errorMessage;
    super(errorMessage);
    this.name = errorName;
    this.message = `${errorMessage}`;
  }
};

// src/components/scope.ts
var Scope = class {
  constructor(parentScope) {
    this._variables = /* @__PURE__ */ new Map();
    this._isLoop = false;
    this._isBreakStatement = false;
    this._isContinueStatement = false;
    this._parentScope = parentScope;
  }
  isLoop() {
    return this._isLoop;
  }
  setLoop(isLoop) {
    this._isLoop = isLoop;
  }
  setBreakStatement(isBreakStatement) {
    this._isBreakStatement = isBreakStatement;
  }
  setContinueStatement(isContinueStatement) {
    this._isContinueStatement = isContinueStatement;
  }
  isBreakStatement() {
    return this._isBreakStatement;
  }
  isContinueStatement() {
    return this._isContinueStatement;
  }
  get(identifier) {
    if (this._variables.has(identifier)) {
      return this._variables.get(identifier);
    }
    if (this._parentScope !== null) {
      return this._parentScope.get(identifier);
    }
    throw new RuntimeException(`Variable "${identifier}" bana to le pehle.`);
  }
  assign(identifier, value) {
    if (this._variables.has(identifier)) {
      this._variables.set(identifier, value);
      return;
    }
    if (this._parentScope !== null) {
      this._parentScope.assign(identifier, value);
      return;
    }
    throw new RuntimeException(`Variable "${identifier}" bana to le pehle fir assign karna.`);
  }
  declare(identifier, value) {
    if (this._variables.has(identifier)) {
      throw new RuntimeException(`Variable "${identifier}" pehle se exist karta hai bhai. Check karle.`);
    }
    this._variables.set(identifier, value);
  }
};

// src/components/interpreter.ts
var Interpreter = class {
  constructor(parserObj, scope) {
    this._parser = parserObj;
    this._scope = scope;
  }
  interpret(code) {
    try {
      const ast = this._parser.parse(code);
      InterpreterModule.getVisitor(ast.type).visitNode(ast);
    } finally {
      InterpreterModule.setCurrentScope(new Scope(null));
    }
  }
};

// src/components/visitor/assignmentExpression.ts
init_cjs_shims();

// src/exceptions/invalidStateException.ts
init_cjs_shims();
var InvalidStateException = class extends Error {
  constructor(errorMessage) {
    super(errorMessage);
    this.name = "InvalidStateException";
    this.message = errorMessage;
  }
};

// src/exceptions/nallaPointerException.ts
init_cjs_shims();
var NallaPointerException = class extends Error {
  constructor(errorMessage) {
    const errorName = "NallaPointerException";
    errorMessage = errorName + ": " + errorMessage;
    super(errorMessage);
    this.name = errorName;
    this.message = errorMessage;
  }
};

// src/helpers/index.ts
init_cjs_shims();
function checkNumberOperands(operands) {
  return typeof operands.left === "number" && typeof operands.right === "number";
}
function checkStringOperands(operands) {
  return typeof operands.left === "string" && typeof operands.right === "string";
}
function checkNumberAndStringOperands(operands) {
  return typeof operands.left === "string" && typeof operands.right === "number" || typeof operands.right === "string" && typeof operands.left === "number";
}
function getOperationValue(operands, operator) {
  const exception = new RuntimeException(`Ye kya kar raha hai: "${operator}" ke sath "${typeof operands.left}" aur "${typeof operands.right}" nahi jamte.`);
  switch (operator) {
    case "=":
      return operands.right;
    case "+=":
    case "+":
      if (checkNumberOperands(operands)) {
        return operands.left + operands.right;
      }
      if (checkStringOperands(operands)) {
        return operands.left + operands.right;
      }
      if (checkNumberAndStringOperands(operands)) {
        return operands.left.toString() + operands.right.toString();
      }
      throw exception;
    case "-=":
    case "-":
      if (checkNumberOperands(operands)) {
        return operands.left - operands.right;
      }
      throw exception;
    case "*=":
    case "*":
      if (checkNumberOperands(operands)) {
        return operands.left * operands.right;
      }
      throw exception;
    case "/=":
    case "/":
      if (operands.right === 0) {
        throw new RuntimeException(`Kya kar rha hai tu??...zero se divide ni karte`);
      }
      if (checkNumberOperands(operands)) {
        return operands.left / operands.right;
      }
      throw exception;
    case "%=":
    case "%":
      if (checkNumberOperands(operands)) {
        return operands.left % operands.right;
      }
      throw exception;
    case "==":
      return operands.left === operands.right;
    case "!=":
      return operands.left !== operands.right;
    case ">":
      if (checkNumberOperands(operands)) {
        return operands.left > operands.right;
      }
      throw exception;
    case "<":
      if (checkNumberOperands(operands)) {
        return operands.left < operands.right;
      }
      throw exception;
    case ">=":
      if (checkNumberOperands(operands)) {
        return operands.left >= operands.right;
      }
      throw exception;
    case "<=":
      if (checkNumberOperands(operands)) {
        return operands.left <= operands.right;
      }
      throw exception;
    case "&&":
      return operands.left && operands.right;
    case "||":
      return operands.left || operands.right;
    default:
      throw new InvalidStateException(`Unsupported operator: ${operator}`);
  }
}

// src/components/visitor/assignmentExpression.ts
var AssignmentExpression = class {
  visitNode(node) {
    if (!node.left)
      throw new InvalidStateException(`left node not present while executing: ${node.type}`);
    let identifier = node.left.name;
    let value;
    const currentScope = InterpreterModule.getCurrentScope();
    if (node.right) {
      value = InterpreterModule.getVisitor(node.right.type).visitNode(node.right);
    }
    if (identifier && node.operator) {
      const left = currentScope.get(identifier);
      if (left === null && node.operator !== "=")
        throw new NallaPointerException(`Nalla operand ni jamta "${node.operator}" ke sath`);
      if ((left === true || left === false) && node.operator !== "=")
        throw new RuntimeException(`Boolean operand ni jamta "${node.operator}" ke sath`);
      const newValue = getOperationValue({ left: currentScope.get(identifier), right: value }, node.operator);
      currentScope.assign(identifier, newValue);
      return currentScope.get(identifier);
    }
  }
};

// src/components/visitor/binaryExpression.ts
init_cjs_shims();
var import_bhai_lang_parser = __toESM(require_dist());
var BinaryExpression = class {
  visitNode(node) {
    if (!node.left || !node.right || !node.operator) {
      throw new InvalidStateException(`Left , right or operator not found for: ${node.type}`);
    }
    let left, right;
    if (node.type == import_bhai_lang_parser.NodeType.BinaryExpression) {
      if (node.operator !== "==" && node.operator !== "!=") {
        this._checkNalla(node);
        this._checkBoolean(node);
      }
      left = this._getNodeValue(node.left);
      right = this._getNodeValue(node.right);
    } else if (node.type == import_bhai_lang_parser.NodeType.LogicalExpression) {
      this._checkNalla(node);
      left = node.left.type == import_bhai_lang_parser.NodeType.BooleanLiteral ? node.left.value == "sahi" ? true : false : InterpreterModule.getVisitor(node.left.type).visitNode(node.left);
      right = node.right.type == import_bhai_lang_parser.NodeType.BooleanLiteral ? node.right.value == "sahi" ? true : false : InterpreterModule.getVisitor(node.right.type).visitNode(node.right);
    }
    return getOperationValue({ left, right }, node.operator);
  }
  _checkNalla(node) {
    if (!node.left || !node.right || !node.operator) {
      throw new InvalidStateException(`Left , right or operator not found for: ${node.type}`);
    }
    const nallaException = new NallaPointerException(`Nalla operand ni jamta "${node.operator}" ke sath`);
    if (node.left.type === import_bhai_lang_parser.NodeType.NullLiteral || node.right.type === import_bhai_lang_parser.NodeType.NullLiteral)
      throw nallaException;
    if (node.left.type === import_bhai_lang_parser.NodeType.IdentifierExpression && node.left.name) {
      const value = InterpreterModule.getCurrentScope().get(node.left.name);
      if (value === null)
        throw nallaException;
    }
    if (node.right.type === import_bhai_lang_parser.NodeType.IdentifierExpression && node.right.name) {
      const value = InterpreterModule.getCurrentScope().get(node.right.name);
      if (value === null)
        throw nallaException;
    }
  }
  _checkBoolean(node) {
    if (!node.left || !node.right || !node.operator) {
      throw new InvalidStateException(`Left , right or operator not found for: ${node.type}`);
    }
    const runtimeException = new RuntimeException(`Kya kar rha hai tu??..Boolean operand ni jamta "${node.operator}" ke sath`);
    if (node.left.type === import_bhai_lang_parser.NodeType.BooleanLiteral || node.right.type === import_bhai_lang_parser.NodeType.BooleanLiteral)
      throw runtimeException;
    if (node.left.type === import_bhai_lang_parser.NodeType.IdentifierExpression && node.left.name) {
      const value = InterpreterModule.getCurrentScope().get(node.left.name);
      if (value === true || value === false)
        throw runtimeException;
    }
    if (node.right.type === import_bhai_lang_parser.NodeType.IdentifierExpression && node.right.name) {
      const value = InterpreterModule.getCurrentScope().get(node.right.name);
      if (value === true || value === false)
        throw runtimeException;
    }
  }
  _getNodeValue(node) {
    if (node.type === import_bhai_lang_parser.NodeType.NullLiteral)
      return null;
    if (node.type === import_bhai_lang_parser.NodeType.BooleanLiteral) {
      return node.value === "sahi" ? true : false;
    }
    if (node.type === import_bhai_lang_parser.NodeType.IdentifierExpression && node.name)
      return InterpreterModule.getCurrentScope().get(node.name);
    return InterpreterModule.getVisitor(node.type).visitNode(node);
  }
};

// src/components/visitor/blockStatement.ts
init_cjs_shims();
var BlockStatement = class {
  visitNode(node) {
    const parentScope = InterpreterModule.getCurrentScope();
    InterpreterModule.setCurrentScope(new Scope(parentScope));
    InterpreterModule.getCurrentScope().setLoop(parentScope.isLoop());
    if (Array.isArray(node.body)) {
      node.body.every((statement) => {
        if (InterpreterModule.getCurrentScope().isBreakStatement()) {
          return false;
        }
        if (InterpreterModule.getCurrentScope().isContinueStatement()) {
          parentScope.setContinueStatement(true);
          return false;
        }
        InterpreterModule.getVisitor(statement.type).visitNode(statement);
        return true;
      });
    }
    parentScope.setBreakStatement(InterpreterModule.getCurrentScope().isBreakStatement());
    InterpreterModule.setCurrentScope(parentScope);
  }
};

// src/components/visitor/booleanLiteral.ts
init_cjs_shims();
var BooleanLiteral = class {
  visitNode(node) {
    return node.value;
  }
};

// src/components/visitor/breakStatement.ts
init_cjs_shims();
var BreakStatement = class {
  visitNode() {
    if (InterpreterModule.getCurrentScope().isLoop()) {
      InterpreterModule.getCurrentScope().setBreakStatement(true);
    } else {
      throw new RuntimeException(`Kya "bas kar bhai"?? Loop kahan hai?`);
    }
  }
};

// src/components/visitor/continueStatement.ts
init_cjs_shims();
var ContinueStatement = class {
  visitNode() {
    if (InterpreterModule.getCurrentScope().isLoop()) {
      InterpreterModule.getCurrentScope().setContinueStatement(true);
    } else {
      throw new RuntimeException(`Kha "agla dekh bhai"?? Loop kidhar hai?`);
    }
  }
};

// src/components/visitor/emptyStatement.ts
init_cjs_shims();
var EmptyStatement = class {
  visitNode(_) {
    return;
  }
};

// src/components/visitor/expressionStatement.ts
init_cjs_shims();
var ExpressionStatement = class {
  visitNode(node) {
    if (node.expression) {
      InterpreterModule.getVisitor(node.expression.type).visitNode(node.expression);
    }
  }
};

// src/components/visitor/identifierExpression.ts
init_cjs_shims();
var IdentifierExpression = class {
  visitNode(node) {
    if (!node.name) {
      throw new InvalidStateException(`Invalid node name for: ${node.type}`);
    }
    let value = InterpreterModule.getCurrentScope().get(node.name);
    if (value === null)
      value = "nalla";
    else if (value === true)
      value = "sahi";
    else if (value === false)
      value = "galat";
    return value;
  }
};

// src/components/visitor/ifStatement.ts
init_cjs_shims();
var IfStatement = class {
  evaluateNode(node, parentScope) {
    if (node) {
      InterpreterModule.setCurrentScope(new Scope(parentScope));
      InterpreterModule.getCurrentScope().setLoop(parentScope.isLoop());
      InterpreterModule.getVisitor(node.type).visitNode(node);
    }
  }
  visitNode(node) {
    const test = node.test;
    const parentScope = InterpreterModule.getCurrentScope();
    if (test) {
      const testResult = InterpreterModule.getVisitor(test.type).visitNode(test);
      if (testResult === true || testResult === "sahi") {
        this.evaluateNode(node.consequent, parentScope);
      } else {
        const alternates = node.alternates;
        if (alternates && alternates.length > 0) {
          for (var alternate of alternates) {
            const alternateTest = alternate.test;
            if (!alternateTest) {
              this.evaluateNode(alternate, parentScope);
              break;
            } else {
              const testResult2 = InterpreterModule.getVisitor(alternateTest.type).visitNode(alternateTest);
              if (testResult2 === true || testResult2 === "sahi") {
                this.evaluateNode(alternate.consequent, parentScope);
                break;
              }
            }
          }
        }
      }
    }
    parentScope.setBreakStatement(InterpreterModule.getCurrentScope().isBreakStatement());
    parentScope.setContinueStatement(InterpreterModule.getCurrentScope().isContinueStatement());
    InterpreterModule.setCurrentScope(parentScope);
  }
};

// src/components/visitor/initStatement.ts
init_cjs_shims();
var InitStatement = class {
  visitNode(node) {
    if (Array.isArray(node.body)) {
      node.body.forEach((statement) => {
        InterpreterModule.getVisitor(statement.type).visitNode(statement);
      });
    }
  }
};

// src/components/visitor/nullLiteral.ts
init_cjs_shims();
var NullLiteral = class {
  visitNode(node) {
    return node.value;
  }
};

// src/components/visitor/numericLiteral.ts
init_cjs_shims();
var NumericLiteral = class {
  visitNode(node) {
    return node.value;
  }
};

// src/components/visitor/printStatement.ts
init_cjs_shims();
var PrintStatement = class {
  visitNode(node) {
    if (!node.expressions)
      throw new InvalidStateException(`No expressions to print: ${node.expressions}`);
    const value = node.expressions.map((expression) => {
      let currentNodeOutput = InterpreterModule.getVisitor(expression.type).visitNode(expression);
      if (currentNodeOutput === true)
        currentNodeOutput = "sahi";
      else if (currentNodeOutput === false)
        currentNodeOutput = "galat";
      return currentNodeOutput;
    }).join(" ");
    console.log(value);
  }
};

// src/components/visitor/program.ts
init_cjs_shims();
var Program = class {
  visitNode(node) {
    if (Array.isArray(node.body) || !node.body)
      throw new InvalidStateException(`Invalid node body for : ${node.type}`);
    InterpreterModule.getVisitor(node.body.type).visitNode(node.body);
  }
};

// src/components/visitor/stringLiteral.ts
init_cjs_shims();
var StringLiteral = class {
  visitNode(node) {
    return node.value;
  }
};

// src/components/visitor/variableDeclaration.ts
init_cjs_shims();
var import_bhai_lang_parser2 = __toESM(require_dist());
var VariableDeclaration = class {
  visitNode(node) {
    if (!node.id || !node.init) {
      throw new InvalidStateException(`id or init not found for ${node.type}`);
    }
    const identifier = node.id.name;
    let value;
    if (node.init.type === import_bhai_lang_parser2.NodeType.NullLiteral)
      value = null;
    else if (node.init.type === import_bhai_lang_parser2.NodeType.BooleanLiteral)
      value = node.init.value === "sahi" ? true : false;
    else
      value = InterpreterModule.getVisitor(node.init.type).visitNode(node.init);
    const currentScope = InterpreterModule.getCurrentScope();
    if (identifier) {
      currentScope.declare(identifier, value);
    }
  }
};

// src/components/visitor/variableStatement.ts
init_cjs_shims();
var VariableStatement = class {
  visitNode(node) {
    if (!node.declarations)
      throw new InvalidStateException(`variable declarations in variable statement is not present: ${node.declarations}`);
    node.declarations.forEach((declaration) => {
      InterpreterModule.getVisitor(declaration.type).visitNode(declaration);
    });
  }
};

// src/components/visitor/whileStatement.ts
init_cjs_shims();
var WhileStatement = class {
  visitNode(node) {
    const test = node.test;
    if (test) {
      const getConditionValue = () => InterpreterModule.getVisitor(test.type).visitNode(test);
      const parentScope = InterpreterModule.getCurrentScope();
      InterpreterModule.setCurrentScope(new Scope(parentScope));
      InterpreterModule.getCurrentScope().setLoop(true);
      for (let testResult = getConditionValue(), executions = 0; testResult === true || testResult === "sahi"; testResult = getConditionValue(), executions++) {
        if (InterpreterModule.getCurrentScope().isBreakStatement()) {
          break;
        }
        if (executions > 5e3) {
          throw new RuntimeException("Bohot jyada hi chale jaa rha hai loop");
        }
        if (InterpreterModule.getCurrentScope().isContinueStatement()) {
          InterpreterModule.getCurrentScope().setContinueStatement(false);
          continue;
        }
        const body = node.body;
        if (body && !Array.isArray(body)) {
          InterpreterModule.getVisitor(body.type).visitNode(body);
        }
      }
      InterpreterModule.setCurrentScope(parentScope);
    }
  }
};

// src/module/interpreterModule.ts
var _InterpreterModule = class {
  static getVisitor(nodeType) {
    const visitor = _InterpreterModule._visitorMap[nodeType];
    if (!visitor)
      throw new InvalidStateException(`Couldn't find any visitor object for nodeType: ${nodeType}`);
    return visitor;
  }
  static getInterpreter() {
    var _a;
    this._interpreter = (_a = this._interpreter) != null ? _a : new Interpreter(import_bhai_lang_parser3.default, this.getCurrentScope());
    return this._interpreter;
  }
  static getCurrentScope() {
    var _a;
    this._currentScope = (_a = this._currentScope) != null ? _a : new Scope(null);
    return this._currentScope;
  }
  static setCurrentScope(scope) {
    this._currentScope = scope;
  }
};
var InterpreterModule = _InterpreterModule;
InterpreterModule._visitorMap = {
  [import_bhai_lang_parser3.NodeType.Program]: new Program(),
  [import_bhai_lang_parser3.NodeType.InitStatement]: new InitStatement(),
  [import_bhai_lang_parser3.NodeType.PrintStatement]: new PrintStatement(),
  [import_bhai_lang_parser3.NodeType.EmptyStatement]: new EmptyStatement(),
  [import_bhai_lang_parser3.NodeType.BlockStatement]: new BlockStatement(),
  [import_bhai_lang_parser3.NodeType.VariableStatement]: new VariableStatement(),
  [import_bhai_lang_parser3.NodeType.IdentifierExpression]: new IdentifierExpression(),
  [import_bhai_lang_parser3.NodeType.VariableDeclaration]: new VariableDeclaration(),
  [import_bhai_lang_parser3.NodeType.AssignmentExpression]: new AssignmentExpression(),
  [import_bhai_lang_parser3.NodeType.ExpressionStatement]: new ExpressionStatement(),
  [import_bhai_lang_parser3.NodeType.BinaryExpression]: new BinaryExpression(),
  [import_bhai_lang_parser3.NodeType.LogicalExpression]: new BinaryExpression(),
  [import_bhai_lang_parser3.NodeType.StringLiteral]: new StringLiteral(),
  [import_bhai_lang_parser3.NodeType.NumericLiteral]: new NumericLiteral(),
  [import_bhai_lang_parser3.NodeType.BooleanLiteral]: new BooleanLiteral(),
  [import_bhai_lang_parser3.NodeType.NullLiteral]: new NullLiteral(),
  [import_bhai_lang_parser3.NodeType.IfStatement]: new IfStatement(),
  [import_bhai_lang_parser3.NodeType.WhileStatement]: new WhileStatement(),
  [import_bhai_lang_parser3.NodeType.BreakStatement]: new BreakStatement(),
  [import_bhai_lang_parser3.NodeType.ContinueStatement]: new ContinueStatement()
};

// src/index.ts
var src_default = InterpreterModule.getInterpreter();
module.exports = __toCommonJS(src_exports);
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  RuntimeException
});
