export default function BhaiLangLogo() {
  return (
    <svg
      className="h-min w-full"
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="824.000000pt"
      height="196.000000pt"
      viewBox="0 0 824.000000 196.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <metadata></metadata>
      <g
        transform="translate(0.000000,196.000000) scale(0.100000,-0.100000)"
        fill="#FF5733"
        stroke="none"
      >
        <path
          d="M694 1951 c8 -12 -8 -13 -159 -12 -74 1 -105 -4 -175 -28 -83 -28
-85 -30 -93 -67 -9 -46 2 -83 43 -134 l31 -39 -16 -46 c-8 -26 -20 -67 -26
-93 -6 -26 -12 -54 -15 -62 -20 -80 -29 -106 -39 -115 -12 -9 -33 -101 -40
-170 -2 -16 -6 -44 -9 -62 -3 -17 -2 -35 4 -38 5 -3 10 -14 10 -23 0 -14 -2
-15 -10 -2 -12 19 -15 15 -29 -55 -19 -93 -52 -246 -62 -285 -5 -19 -16 -69
-25 -110 -9 -41 -21 -79 -25 -85 -5 -5 -9 -26 -9 -47 0 -47 26 -63 48 -30 15
22 39 32 26 10 -11 -18 -11 -59 0 -66 6 -4 29 -14 51 -23 l40 -16 -35 -8 c-19
-4 -59 -16 -89 -28 -49 -18 -53 -21 -37 -33 17 -12 17 -13 -9 -20 -31 -8 -54
-52 -33 -66 36 -24 349 30 588 103 241 72 402 170 484 292 57 87 69 129 70
242 1 90 -2 106 -26 156 -16 31 -47 75 -70 99 -42 42 -166 110 -200 110 -35 0
-25 18 36 63 64 47 188 171 184 185 -1 4 9 27 21 52 33 62 39 160 16 231 -20
60 -96 143 -156 172 -77 36 -280 73 -265 48z m180 -232 c-2 -4 1 -11 9 -16 22
-14 58 -66 49 -72 -4 -3 -13 -24 -20 -47 -6 -22 -25 -55 -42 -72 -16 -18 -30
-37 -30 -43 0 -13 -96 -106 -162 -157 -27 -20 -48 -39 -48 -42 0 -3 -14 -14
-30 -24 l-31 -18 14 34 c7 18 25 39 39 45 14 7 46 35 72 63 26 27 52 50 59 50
12 0 66 60 119 130 28 37 34 97 13 131 -12 19 -67 49 -90 49 -9 0 -14 4 -11 8
3 5 25 4 50 -2 24 -6 43 -13 40 -17z m-59 -28 c26 -5 30 -10 27 -36 -2 -20 0
-26 6 -17 7 11 10 11 15 -2 9 -24 -13 -66 -35 -66 -13 0 -18 -7 -18 -25 0 -18
-5 -25 -20 -25 -11 0 -20 -6 -20 -13 0 -13 -25 -46 -94 -123 -39 -45 -74 -60
-61 -26 4 9 26 71 50 137 23 66 51 139 60 163 18 44 25 47 90 33z m-520 -566
c-19 -83 -25 -155 -14 -155 5 0 12 14 16 30 3 17 9 30 13 30 5 0 8 15 8 33 0
30 17 87 25 87 9 0 -15 -109 -39 -174 -13 -38 -24 -77 -24 -87 0 -9 -6 -19
-12 -22 -10 -3 -10 -7 1 -20 9 -12 10 -17 2 -17 -6 0 -14 7 -17 15 -9 22 -27
18 -21 -5 3 -11 1 -20 -4 -20 -5 0 -9 -9 -9 -21 0 -11 -5 -29 -10 -40 -9 -15
-7 -21 5 -25 17 -7 20 -36 5 -62 -5 -9 -16 -39 -24 -67 -12 -39 -15 -42 -11
-15 2 19 7 45 11 58 4 13 2 22 -4 22 -6 0 -14 -17 -18 -37 -4 -21 -11 -44 -17
-51 -5 -6 -13 -27 -17 -45 l-8 -32 -1 38 c-1 21 5 48 13 60 8 12 17 40 21 62
4 22 11 45 15 50 5 6 9 20 8 30 -1 22 58 268 72 300 9 22 20 68 23 100 2 21
17 49 23 43 2 -2 -3 -31 -11 -63z m233 -228 c8 -6 78 -13 157 -16 83 -4 149
-11 155 -17 21 -21 23 -82 5 -129 -9 -25 -21 -45 -26 -45 -5 0 -9 -12 -9 -27
0 -21 -3 -24 -10 -13 -7 12 -13 10 -32 -11 -35 -37 -160 -117 -247 -156 -42
-19 -84 -45 -94 -57 -10 -11 -16 -15 -12 -8 9 17 -8 15 -58 -7 -102 -45 -113
-46 -84 -12 8 9 49 115 92 236 107 299 100 284 127 278 13 -3 29 -11 36 -16z"
        />
        <path
          d="M4615 1916 c-5 -2 -35 -12 -65 -21 -30 -9 -72 -30 -92 -46 -20 -16
-41 -29 -45 -29 -12 0 -69 -97 -83 -142 -6 -21 -20 -70 -30 -110 -33 -130 -49
-181 -59 -187 -17 -11 -72 -301 -61 -322 13 -23 13 -39 0 -19 -12 19 -28 -21
-42 -105 -8 -52 -28 -143 -68 -313 -5 -23 -10 -51 -10 -61 0 -11 -12 -33 -26
-50 -64 -77 -84 -225 -44 -338 24 -69 26 -70 48 -50 58 52 574 234 831 292
129 29 139 37 121 99 -9 34 -18 46 -31 46 -11 0 -19 -4 -19 -10 0 -16 -46 -12
-60 5 -7 8 -18 15 -24 15 -6 0 -24 7 -40 15 -24 13 -55 14 -175 9 -94 -4 -149
-10 -153 -17 -6 -9 -8 -8 -8 2 0 11 -16 12 -85 6 -47 -4 -85 -5 -85 -3 0 3 65
182 144 399 249 683 297 802 337 846 12 12 19 25 16 30 -18 29 -157 72 -192
59z m-339 -792 c-7 -33 -15 -65 -18 -70 -12 -18 -18 -124 -8 -124 5 0 10 8 10
18 0 9 7 30 15 46 9 16 13 36 11 43 -3 7 -1 13 4 13 12 0 13 -42 2 -56 -13
-14 -52 -137 -52 -162 0 -12 -5 -22 -11 -22 -6 0 -9 -9 -6 -20 3 -11 1 -23 -4
-26 -5 -3 -9 4 -9 15 0 42 -18 19 -25 -31 -4 -29 -11 -57 -15 -63 -13 -17 -21
-95 -10 -95 5 0 10 12 11 28 0 15 3 21 6 13 7 -17 -17 -91 -29 -91 -5 0 -6 5
-3 10 3 6 1 10 -4 10 -6 0 -11 -4 -11 -10 0 -5 -4 -10 -10 -10 -11 0 -2 60 11
74 5 6 9 21 9 34 0 13 9 56 20 95 11 40 31 115 44 167 14 52 32 120 41 150 9
30 18 67 19 81 3 25 15 53 21 47 2 -2 -2 -31 -9 -64z"
        />
        <path
          d="M6987 1813 c-3 -27 -45 -178 -92 -338 -90 -304 -140 -496 -171 -655
-10 -52 -21 -99 -24 -103 -4 -4 -17 38 -29 95 -53 248 -85 554 -62 596 10 18
9 23 -3 28 -8 3 -22 28 -32 55 -26 70 -110 154 -176 174 -73 21 -75 21 -81
-32 -3 -27 -37 -149 -75 -273 -114 -370 -149 -499 -190 -705 -58 -287 -62
-475 -12 -475 5 0 22 11 38 25 16 13 32 22 35 19 3 -3 11 14 17 38 7 23 25 82
41 129 33 97 34 103 17 94 -7 -4 -3 2 8 12 12 10 34 50 49 88 15 39 43 100 61
137 36 71 42 94 22 82 -7 -4 -8 -3 -4 4 4 7 13 12 19 12 7 0 21 19 31 43 16
36 20 39 26 22 130 -346 192 -466 286 -560 l35 -35 27 28 c15 16 35 31 44 35
10 3 19 22 23 44 6 39 59 213 75 248 6 11 21 49 34 85 13 36 41 101 62 144 26
56 34 81 25 84 -8 3 -6 9 7 18 24 16 132 225 132 254 0 11 4 28 9 38 14 25 76
160 95 205 8 21 16 59 17 85 1 51 -19 142 -33 142 -4 0 -8 6 -8 13 0 21 -102
115 -142 132 -21 8 -50 15 -66 15 -27 0 -30 -3 -35 -47z"
        />
        <path
          d="M2285 1781 c-44 -11 -122 -58 -161 -99 -35 -36 -60 -103 -104 -282
-12 -47 -28 -98 -35 -115 -17 -38 -24 -62 -34 -122 -5 -26 -21 -106 -35 -177
-14 -72 -26 -131 -26 -131 0 -1 -51 -5 -112 -8 -62 -4 -123 -9 -135 -13 l-23
-6 19 53 c37 107 170 437 199 495 17 33 37 66 46 73 24 21 20 27 -36 52 -81
36 -202 34 -284 -4 -85 -40 -106 -72 -139 -216 -24 -102 -33 -134 -43 -140
-21 -14 -56 -225 -42 -251 8 -15 7 -18 -3 -14 -10 3 -17 -14 -27 -68 -7 -40
-16 -89 -21 -108 -4 -19 -13 -66 -20 -105 -6 -38 -13 -81 -16 -95 -2 -14 -7
-46 -10 -73 -3 -26 -10 -50 -14 -53 -11 -6 -12 -75 -1 -90 4 -6 17 -10 29 -9
13 1 23 -5 26 -17 4 -13 15 -18 39 -18 79 0 71 -12 160 235 l54 149 75 23 c41
12 112 34 158 48 46 14 86 23 88 21 2 -2 -1 -34 -8 -70 -6 -37 -9 -82 -5 -99
4 -20 3 -27 -3 -18 -11 18 -15 4 -31 -109 -7 -47 -16 -98 -21 -114 -16 -51
-11 -116 9 -123 9 -4 23 -15 30 -25 9 -12 23 -18 40 -16 24 3 30 14 78 158 28
85 99 279 159 430 326 831 360 913 401 967 29 38 27 41 -31 72 -41 22 -126 27
-190 12z"
        />
        <path
          d="M3798 1733 c-21 -2 -38 -8 -38 -14 0 -5 -4 -9 -10 -9 -22 0 -106 -61
-127 -92 -24 -37 -49 -105 -68 -188 -22 -93 -44 -163 -53 -169 -9 -6 -37 -122
-47 -196 -1 -5 -5 -26 -9 -46 -5 -23 -4 -45 3 -58 9 -17 9 -20 -3 -15 -16 6
-17 3 -57 -191 -31 -151 -34 -180 -19 -170 6 3 10 -4 10 -17 0 -18 -2 -20 -10
-8 -5 8 -12 11 -15 8 -10 -9 -35 -146 -35 -185 0 -18 -5 -33 -10 -33 -7 0 -10
-22 -8 -57 3 -56 4 -58 30 -57 20 0 28 -6 32 -22 5 -20 12 -22 52 -20 31 2 51
9 60 21 7 10 30 68 50 129 20 61 81 233 136 381 265 720 333 891 373 935 22
25 20 29 -37 55 -52 25 -98 28 -200 18z m-224 -641 c-18 -11 -34 -49 -34 -77
0 -14 -4 -34 -9 -43 -13 -25 -25 -132 -14 -132 10 0 37 87 34 108 -1 6 3 12 9
12 15 0 12 -33 -5 -66 -8 -16 -15 -37 -15 -46 0 -9 -4 -19 -9 -22 -5 -4 -12
-24 -16 -46 -4 -22 -11 -43 -16 -46 -6 -3 -7 -15 -3 -26 4 -14 2 -18 -5 -14
-6 4 -11 14 -11 22 0 8 -5 12 -11 8 -6 -3 -9 -15 -6 -25 3 -11 2 -19 -3 -19
-4 0 -10 -20 -13 -45 -4 -25 -9 -56 -13 -70 -4 -15 -2 -25 5 -25 6 0 11 10 12
23 0 12 3 17 5 10 3 -6 -2 -33 -10 -60 -8 -28 -15 -39 -15 -25 -1 36 -19 25
-27 -15 -4 -21 -11 -45 -16 -53 -6 -10 -7 -6 -4 15 3 17 8 49 11 72 4 24 11
45 16 49 5 3 9 16 9 29 0 31 68 314 80 337 6 10 10 29 10 42 0 14 5 28 10 31
6 3 10 24 10 46 0 21 4 39 9 39 5 0 13 9 16 21 5 16 10 18 18 10 9 -9 9 -14 1
-19z"
        />
        <path
          d="M7782 1709 c-119 -38 -213 -121 -302 -266 -17 -29 -36 -53 -40 -53
-21 0 -131 -276 -146 -365 -2 -11 -10 -51 -19 -90 -11 -50 -13 -77 -6 -97 6
-17 7 -37 1 -51 -9 -22 -9 -22 -9 3 -1 19 -4 21 -13 12 -10 -10 -13 -61 -13
-185 0 -153 2 -179 23 -238 26 -76 77 -141 133 -169 68 -35 212 -23 266 22 10
8 11 13 3 18 -7 4 3 16 27 30 33 19 36 20 21 3 -42 -46 -3 -34 65 20 20 17 37
36 37 44 0 7 5 13 12 13 6 0 20 7 30 17 17 15 18 13 18 -63 1 -145 46 -314 84
-314 20 0 36 46 36 108 0 67 21 173 50 249 11 30 18 58 15 63 -3 5 -1 10 4 12
11 4 71 132 71 151 0 14 18 63 46 124 8 17 14 44 14 60 0 20 7 31 23 38 17 8
22 18 21 47 -2 54 -18 69 -73 67 -26 -1 -59 5 -76 13 -16 9 -52 19 -79 23 -27
3 -54 9 -61 12 -10 3 -12 -6 -8 -36 5 -37 3 -41 -15 -41 -12 0 -78 -5 -147
-11 -97 -8 -134 -8 -156 1 -16 7 -29 14 -29 15 0 1 16 42 36 91 38 95 116 247
142 277 41 46 37 -1 -9 -102 -21 -46 -26 -67 -19 -76 19 -23 77 -45 119 -45
54 0 171 57 171 83 0 10 4 16 9 13 10 -6 54 54 72 99 6 17 19 46 27 65 10 23
16 69 16 135 1 90 -2 106 -27 157 -21 44 -42 68 -84 98 -53 38 -60 40 -127 39
-42 0 -97 -8 -134 -20z m-380 -661 c-5 -7 -15 -42 -22 -78 -6 -36 -17 -85 -25
-110 -17 -55 -29 -220 -16 -220 6 0 13 32 17 70 4 39 11 73 15 76 5 3 9 24 9
47 0 31 3 38 12 29 9 -9 8 -29 -5 -84 -27 -119 -35 -180 -31 -243 6 -106 10
-127 27 -137 10 -5 15 -13 12 -18 -8 -13 -35 -1 -35 15 0 8 -4 17 -10 20 -23
14 -8 -38 18 -61 15 -14 27 -30 27 -34 0 -22 31 -34 102 -38 67 -4 81 -2 93
13 14 17 13 18 -25 11 -49 -9 -44 -2 8 12 21 6 35 6 32 1 -4 -5 0 -9 7 -9 20
0 54 27 46 36 -4 4 -14 1 -23 -6 -8 -7 -15 -10 -15 -6 0 3 19 18 43 33 23 15
53 36 66 47 l25 21 -17 -27 c-27 -43 2 -35 48 13 22 22 54 50 70 62 l30 22
-25 -30 c-89 -109 -273 -225 -353 -225 -135 1 -208 115 -206 324 0 56 4 124 9
151 21 118 38 193 44 203 4 7 11 37 16 69 5 31 15 60 22 64 17 11 23 2 10 -13z
m214 -303 c17 -45 73 -105 97 -105 7 0 28 4 47 10 34 9 34 8 -29 -52 -68 -64
-174 -138 -199 -138 -24 0 -11 172 24 310 l29 115 6 -47 c4 -27 15 -68 25 -93z"
        />
        <path
          d="M3003 1652 c-110 -39 -140 -75 -258 -304 -28 -54 -54 -98 -60 -98 -5
0 -30 -46 -54 -103 -24 -56 -52 -119 -62 -139 -9 -21 -15 -48 -12 -60 3 -17 2
-19 -5 -9 -12 17 -174 -309 -175 -352 0 -17 -3 -23 -8 -15 -5 7 -24 -24 -53
-85 -25 -53 -46 -100 -46 -103 0 -3 -6 -11 -13 -18 -7 -8 -19 -32 -27 -56 -13
-38 -12 -44 3 -55 9 -6 14 -15 10 -19 -9 -9 18 -56 33 -56 22 0 57 40 155 175
79 109 98 129 117 127 25 -4 120 18 204 47 29 10 70 21 91 25 34 6 37 5 37
-16 1 -13 3 -45 5 -73 18 -224 25 -249 70 -271 19 -9 41 -14 48 -11 8 3 17 46
25 128 6 68 23 180 36 249 55 284 139 683 166 791 65 259 49 308 -103 316 -53
3 -86 -1 -124 -15z m-134 -829 c1 -65 4 -126 7 -135 5 -16 -5 -18 -106 -18
l-112 0 32 43 c18 23 62 84 98 135 37 50 69 92 73 92 3 0 7 -53 8 -117z"
        />
        <path
          d="M5735 1654 c-118 -43 -144 -71 -235 -250 -36 -71 -73 -137 -84 -147
-16 -17 -42 -69 -118 -236 -12 -27 -17 -54 -13 -70 4 -19 3 -22 -4 -11 -13 19
-13 19 -56 -75 -20 -44 -57 -120 -82 -168 -26 -52 -43 -97 -40 -108 3 -10 1
-17 -3 -14 -11 7 -40 -34 -40 -56 0 -9 -6 -22 -14 -28 -7 -6 -21 -33 -31 -59
-10 -26 -23 -54 -30 -62 -7 -8 -20 -35 -28 -60 -12 -36 -12 -46 -2 -55 7 -5
16 -23 19 -38 9 -38 32 -44 63 -16 14 13 68 83 119 156 69 96 99 130 110 126
19 -7 104 11 199 43 39 12 84 26 102 29 36 7 30 29 53 -189 13 -114 26 -169
40 -161 4 3 13 -2 20 -10 7 -8 23 -15 35 -15 25 0 35 30 35 105 0 89 134 780
211 1088 35 141 38 228 10 256 -40 40 -159 53 -236 25z m-141 -846 l7 -138
-106 0 c-58 0 -105 2 -105 5 0 3 31 47 68 97 38 51 81 112 97 136 15 23 29 41
30 40 1 -2 5 -65 9 -140z"
        />
      </g>
    </svg>
  );
}
